
import { BButton, BTitle } from 'buetify/lib/components';
import { getUseTogglePropsDefinition, useToggle } from 'buetify/lib/composables';
import { defineComponent, computed, PropType as PType } from 'vue';
import { useHighlightedCode } from '../../shared/composables/useHighlightedCode';
import CodeIcon from '../icons/CodeIcon';
import EyeSlashIcon from '../icons/EyeSlashIcon';

export default defineComponent({
	name: 'code-view',
	components: {
		BTitle,
		BButton,
		CodeIcon,
		EyeSlashIcon
	},
	props: {
		lang: {
			type: String as PType<'typescript' | 'html' | 'bash'>,
			default: 'html' as const
		},
		code: {
			type: String,
			required: true
		},
		title: {
			type: String
		},
		isBordered: {
			type: Boolean,
			default: true
		},
		useToggle: {
			type: Boolean,
			default: true
		},
		...getUseTogglePropsDefinition('isExpanded')
	},
	setup(props) {
		const toggle = useToggle(props, 'isExpanded');
		const classes = computed(() => ({
			'is-collapsed': !props.isBordered && !toggle.isOn.value && props.useToggle,
			'is-expanded': toggle.isOn.value || !props.useToggle
		}));

		return {
			...toggle,
			classes,
			highlightedCode: useHighlightedCode(props)
		};
	}
});
